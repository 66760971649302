<template>
  <div class="people">
    <dashboard-layout>
      <template #header>
        <h4 class="align-self-center pb-0 mb-0">
          {{ $t('menu.vmb-menu.people') }}
        </h4>
      </template>
      <template #body>
        <b-row>
          <b-colxx lg="12" md="12" sm="12" class="mb-4">
            <scale-loader :loading="!orderedPeopleData" :color="loader.color"></scale-loader>
            <b-row v-if="orderedPeopleData">
              <b-colxx
                v-for="item in orderedPeopleData"
                :key="item.id"
                xl="3"
                lg="4"
                md="6"
                sm="12"
                class="mb-4 text-center"
              >
                <b-card>
                  <h3 class="mb-1 pb-1">{{ item.details }}</h3>
                  <radial-progress-bar
                    :diameter="200"
                    :strokeWidth="20"
                    :completed-steps="parseInt(item.title)"
                    :total-steps="10"
                    class="mb-2"
                    :startColor="setChartColor(parseInt(item.title))"
                    :stopColor="setChartColor(parseInt(item.title))"
                    animatedSpeed="1000"
                  >
                    <span class="mb-0 pb-0 score">{{ item.title }}</span>
                  </radial-progress-bar>
                </b-card>
              </b-colxx>
            </b-row>
          </b-colxx>
        </b-row>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { mapGetters } from 'vuex'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import RadialProgressBar from 'vue-radial-progress'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
export default {
  components: {
    DashboardLayout,
    RadialProgressBar,
    ScaleLoader
  },
  data() {
    return {
      chartColor: {
        positive: '#03BFAD',
        neutral: '#FFBA52',
        negative: '#F17363'
      },
      loader: {
        color: '#2d7281'
      },
      peopleData: []
    }
  },
  mixins: [dataPreloadMixin],
  computed: {
    ...mapGetters(['vmbObservationData', 'currentSimulation', 'currentSimulationTeam']),
    orderedItems: function () {
      return (data) => _.orderBy(data, ['details'], ['asc'])
    },
    orderedPeopleData() {
      return _.orderBy(this.peopleData, ['details'], ['asc'])
    },
    routeName() {
      return this.$route.name
    },
    setChartColor: function () {
      return (data) => {
        if (data < 6) {
          return this.chartColor.negative
        } else if (data >= 6 && data < 8) {
          return this.chartColor.neutral
        } else {
          return this.chartColor.positive
        }
      }
    }
  },
  watch: {
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchVmbObservationData({
            simulationId: this.currentSimulation.id,
            teamId: newValue.id
          })

          this.peopleData = await _.cloneDeep(this.vmbObservationData[this.routeName])
        }
      },
      immediate: true
    },
    vmbObservationData: {
      async handler(newValue) {
        if (newValue) {
          this.peopleData = await _.cloneDeep(this.vmbObservationData[this.routeName])
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
