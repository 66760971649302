<template>
  <div>
    <dashboard-layout>
      <template #header>
        <h4 class="align-self-center pb-0 mb-0">
          {{ $t('menu.vmb-menu.performance') }}
        </h4>
      </template>
      <template #body>
        <b-row>
          <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <b-container class="chart-title">
                <div class="float-left float-none-xs">
                  <div class="d-inline-block">
                    <h5 class="d-inline">
                      {{ $t('pages.vmb.dashboard.volume') }}
                    </h5>
                  </div>
                </div>
              </b-container>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
                <scale-loader
                  :loading="loadingVmbPerformanceCharts"
                  :color="loader.color"
                ></scale-loader>
                <bar-chart
                  v-if="!loadingVmbPerformanceCharts && claimsProcessedChart"
                  :chart-data="claimsProcessedChart"
                  :options="claimsProcessedChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <b-container class="chart-title">
                <div class="float-left float-none-xs">
                  <div class="d-inline-block">
                    <h5 class="d-inline">
                      {{ $t('pages.vmb.dashboard.accuracy') }}
                    </h5>
                  </div>
                </div>
              </b-container>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
                <scale-loader
                  :loading="loadingVmbPerformanceCharts"
                  :color="loader.color"
                ></scale-loader>
                <bar-chart
                  v-if="!loadingVmbPerformanceCharts && accuracyChart"
                  :chart-data="accuracyChart"
                  :options="accuracyChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xl="4" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <b-container class="chart-title">
                <div class="float-left float-none-xs">
                  <div class="d-inline-block">
                    <h5 class="d-inline">
                      {{ $t('pages.vmb.dashboard.profitability') }}
                    </h5>
                  </div>
                </div>
              </b-container>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
                <scale-loader
                  :loading="loadingVmbPerformanceCharts"
                  :color="loader.color"
                ></scale-loader>
                <bar-chart
                  v-if="!loadingVmbPerformanceCharts && profitabilityChart"
                  :chart-data="profitabilityChart"
                  :options="profitabilityChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xxs="12">
            <b-card class="mb-4 stats-table" :title="$t('pages.vmb.performance.weekly-data')">
              <scale-loader :loading="loadingVmbWeeklyData" :color="loader.color"></scale-loader>
              <b-table
                v-if="!loadingVmbWeeklyData && weeklyData"
                ref="weekly-data-table"
                responsive
                sticky-header
                table-class="text-nowrap"
                :items="weeklyData"
                :fields="weeklyDataFields"
              ></b-table>
            </b-card>
          </b-colxx>
          <b-colxx xxs="12">
            <b-card class="mb-4 stats-table" :title="$t('pages.vmb.performance.yesterday')">
              <scale-loader :loading="loadingVmbYesterdayData" :color="loader.color"></scale-loader>
              <b-table
                v-if="!loadingVmbYesterdayData && yesterdayData"
                ref="yesterday-data-table"
                responsive
                sticky-header
                table-class="text-nowrap"
                :items="yesterdayData"
                :fields="yesterdayDataFields"
                :tbody-tr-class="rowClass"
              ></b-table>
            </b-card>
          </b-colxx>
        </b-row>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  claimsProcessedChartOptions,
  accuracyChartOptions,
  profitabilityChartOptions,
  weeklyDataFields,
  yesterdayDataFields
} from '@/components/Dashboard/config'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import BarChart from '@/components/Charts/Bar'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '@/components/Common/VuetablePaginationBootstrap'
export default {
  components: {
    BarChart,
    DashboardLayout,
    Vuetable,
    VuetablePaginationBootstrap,
    ScaleLoader
  },
  data() {
    return {
      claimsProcessedChartOptions,
      accuracyChartOptions,
      profitabilityChartOptions,
      weeklyDataFields,
      yesterdayDataFields,
      loader: {
        color: '#2d7281'
      }
    }
  },
  mixins: [dataPreloadMixin, pusherMixin],
  methods: {
    ...mapActions([
      'fetchVmbChartData',
      'fetchVmbWeeklyData',
      'fetchVmbYesterdayData',
      'setVmbChartsData'
    ]),
    ...mapMutations(['setVmbWeeklyData', 'setYesterdayData']),
    async initiatePerformanceListener() {
      await Promise.all([
        this.initiateGraphsListener(),
        this.initiateWeeklyPerformanceListener(),
        this.initiateIndividualPerformanceListener()
      ])
    },
    async initiateGraphsListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`performance.graphs.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'performance.graphs',
        (data) => {
          this.setVmbChartsData(data)
        },
        channel.unbind()
      )
    },
    async initiateWeeklyPerformanceListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`performance.weekly-data.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'performance.weekly-data',
        async (data) => {
          await this.setVmbWeeklyData(data.data)
          await this.$refs['weekly-data-table'].refresh()
        },
        channel.unbind()
      )
    },
    async initiateIndividualPerformanceListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`performance.individual.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'performance.individual',
        async (data) => {
          await this.setYesterdayData(data.data)
          await this.$refs['yesterday-data-table'].refresh()
        },
        channel.unbind()
      )
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.name.toLowerCase().trim() === 'total') return 'table-warning'
    }
  },
  created() {},
  computed: {
    ...mapGetters([
      'accuracyChart',
      'claimsProcessedChart',
      'currentSimulation',
      'currentSimulationTeam',
      'currentTeamWeek',
      'currentUser',
      'openTeamWeek',
      'profitabilityChart',
      'vmbCharts',
      'weeklyData',
      'yesterdayData'
    ]),
    ...mapGetters('loading', [
      'loadingVmbPerformanceCharts',
      'loadingVmbWeeklyData',
      'loadingVmbYesterdayData'
    ])
  },
  watch: {
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          const payload = {
            simulationId: this.currentSimulation.id,
            teamId: newValue.id
          }
          await this.fetchVmbChartData(payload)
          await this.fetchVmbWeeklyData(payload)
        }
      },
      immediate: true
    },
    openTeamWeek: {
      async handler(newValue) {
        if (newValue) {
          await this.fetchVmbYesterdayData(this.openTeamWeek.open_week_id)
          await this.initiatePerformanceListener()
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.link-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.75rem 1.75rem 0 0;
}

.chart-title {
  padding: 2rem;
}

.v-spinner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  text-align: center;
  opacity: 0.8;
}

.stats-table {
  max-height: 600px;
}

.b-table-sticky-header {
  max-height: 500px;
}

.avatar {
  display: inline-block;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 99em;
  vertical-align: middle;
}
</style>
