<template>
  <div class="dashboards">
    <dashboard-layout>
      <template #header>
        <h4 class="align-self-center pb-0 mb-0">
          {{ $t('pages.vmb.dashboard.title') }}
        </h4>
      </template>
      <template #header-right>
        <b-button
          v-if="openTeamWeek"
          class="align-self-center btn-shadow mr-4"
          size="sm"
          variant="new"
          @click="onVmbClick"
        >
          {{ $t('pages.vmb.dashboard.update-vmb') }}
        </b-button>
      </template>
      <template #body>
        <b-row>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.what-went-well')" class="dashboard-link-list">
              <scale-loader
                :loading="loadingVmbData || loadingElement === 3"
                :color="loader.color"
              ></scale-loader>
              <to-do-items
                v-if="!loadingVmbData"
                :class="{ loadingElement: loadingElement === 3 }"
                :data="orderedItems(vmbData['what-went-well'])"
                :detail-path="`${vmbRoute}/what-went-well`"
                :disabledDetailPath="!vmbRoute"
                :header="$t('pages.vmb.dashboard.what-went-well')"
                :type="3"
                @clicked="onClickItem"
                @changed="onChangeItem"
              ></to-do-items>
            </b-card>
          </b-colxx>
          <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <scale-loader
                :loading="loadingVmbPerformanceCharts"
                :color="loader.color"
              ></scale-loader>
              <div class="chart-title d-flex">
                <h5 class="d-inline align-self-center">{{ $t('pages.vmb.dashboard.volume') }}</h5>
                <router-link
                  v-if="!loadingVmbPerformanceCharts && claimsProcessedChart && vmbRoute"
                  class="link-icon align-self-center ml-auto"
                  tag="a"
                  :to="`${vmbRoute}/performance`"
                >
                  <i class="fal fa-compass" />
                </router-link>
              </div>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0">
                <bar-chart
                  v-if="claimsProcessedChart"
                  :chart-data="claimsProcessedChart"
                  :options="claimsProcessedChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.problems')" class="dashboard-link-list">
              <scale-loader
                :loading="loadingVmbData || loadingElement === 1"
                :color="loader.color"
              ></scale-loader>
              <to-do-items
                v-if="!loadingVmbData"
                :class="{ loadingElement: loadingElement === 1 }"
                :data="orderedItems(vmbData.problems)"
                :detail-path="`${vmbRoute}/problems`"
                :disabledDetailPath="!vmbRoute"
                :header="$t('pages.vmb.dashboard.problems')"
                :type="1"
                @clicked="onClickItem"
                @changed="onChangeItem"
              ></to-do-items>
            </b-card>
          </b-colxx>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.communications')" class="dashboard-link-list">
              <scale-loader
                :loading="loadingVmbData || loadingElement === 4"
                :color="loader.color"
              ></scale-loader>
              <to-do-items
                v-if="!loadingVmbData"
                :class="{ loadingElement: loadingElement === 4 }"
                :header="$t('pages.vmb.dashboard.communications')"
                :data="orderedItems(vmbData.communication)"
                :detail-path="`${vmbRoute}/communication`"
                :disabledDetailPath="!vmbRoute"
                :type="4"
                @clicked="onClickItem"
                @changed="onChangeItem"
              ></to-do-items>
            </b-card>
          </b-colxx>
          <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <scale-loader
                :loading="loadingVmbPerformanceCharts"
                :color="loader.color"
              ></scale-loader>
              <div class="chart-title d-flex">
                <h5 class="d-inline align-self-center">{{ $t('pages.vmb.dashboard.accuracy') }}</h5>
                <router-link
                  v-if="!loadingVmbPerformanceCharts && accuracyChart && vmbRoute"
                  class="link-icon align-self-center ml-auto"
                  tag="a"
                  :to="`${vmbRoute}/performance`"
                >
                  <i class="fal fa-compass" />
                </router-link>
              </div>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0" v-if="accuracyChart">
                <bar-chart
                  :chart-data="accuracyChart"
                  :options="accuracyChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.actions')" class="dashboard-link-list">
              <scale-loader
                :loading="loadingVmbData || loadingElement === 2"
                :color="loader.color"
              ></scale-loader>
              <to-do-items
                v-if="!loadingVmbData"
                :class="{ loadingElement: loadingElement === 2 }"
                :header="$t('pages.vmb.dashboard.actions')"
                :data="orderedItems(vmbData.actions)"
                :detail-path="`${vmbRoute}/actions`"
                :disabledDetailPath="!vmbRoute"
                :type="2"
                @clicked="onClickItem"
                @changed="onChangeItem"
              ></to-do-items>
            </b-card>
          </b-colxx>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.feedback')" class="dashboard-link-list">
              <scale-loader
                :loading="loadingVmbData || loadingElement === 5"
                :color="loader.color"
              ></scale-loader>
              <to-do-items
                v-if="!loadingVmbData"
                :class="{ loadingElement: loadingElement === 5 }"
                :header="$t('pages.vmb.dashboard.feedback')"
                :data="orderedItems(vmbData.feedback)"
                :detail-path="`${vmbRoute}/feedback`"
                :disabledDetailPath="!vmbRoute"
                :type="5"
                @clicked="onClickItem"
                @changed="onChangeItem"
              ></to-do-items>
            </b-card>
          </b-colxx>
          <b-colxx xl="6" lg="12" sm="12" md="12" class="mb-4">
            <b-card class="dashboard-filled-line-chart dashboard-link-list" no-body>
              <scale-loader
                :loading="loadingVmbPerformanceCharts"
                :color="loader.color"
              ></scale-loader>
              <router-link
                v-if="!loadingVmbPerformanceCharts && profitabilityChart && vmbRoute"
                class="link-icon"
                tag="a"
                :to="`${vmbRoute}/performance`"
              >
                <i class="fal fa-compass" />
              </router-link>
              <b-container class="chart-title">
                <div class="float-left float-none-xs">
                  <div class="d-inline-block">
                    <h5 class="d-inline">{{ $t('pages.vmb.dashboard.profitability') }}</h5>
                  </div>
                </div>
              </b-container>
              <div class="chart card-body pt-0 pl-3 pr-3 pb-0" v-if="profitabilityChart">
                <bar-chart
                  :chart-data="profitabilityChart"
                  :options="profitabilityChartOptions"
                  :height="250"
                />
              </div>
            </b-card>
          </b-colxx>
          <b-colxx xl="3" lg="12" md="12" sm="12" class="mb-4">
            <b-card :title="$t('pages.vmb.dashboard.people')" class="dashboard-link-list">
              <scale-loader :loading="loadingVmbData" :color="loader.color"></scale-loader>
              <router-link
                v-if="!loadingVmbData && vmbData.people"
                tag="a"
                :to="`${vmbRoute}/people`"
                class="link-icon"
              >
                <i class="fal fa-compass" />
              </router-link>
              <vue-perfect-scrollbar
                class="scroll dashboard-list-with-user"
                :settings="{ suppressScrollX: true, wheelPropagation: false }"
              >
                <b-row v-if="vmbData && vmbData.people">
                  <b-colxx
                    v-for="item in vmbData.people"
                    :key="item.id"
                    xl="6"
                    lg="6"
                    md="6"
                    class="pl-0 pr-0 mb-4 border-bottom text-center"
                  >
                    <p class="mb-1 pb-1">{{ item.details }}</p>
                    <radial-progress-bar
                      :diameter="100"
                      :strokeWidth="10"
                      :completed-steps="parseInt(item.title)"
                      :total-steps="10"
                      class="mb-2"
                      :startColor="setChartColor(parseInt(item.title))"
                      :stopColor="setChartColor(parseInt(item.title))"
                    >
                      <h1 class="mb-0 pb-0">{{ item.title }}</h1>
                    </radial-progress-bar>
                  </b-colxx>
                </b-row>
              </vue-perfect-scrollbar>
            </b-card>
          </b-colxx>

          <b-modal
            id="modalright"
            ref="modalright"
            :title="modalData.header"
            v-model="showModal"
            v-if="modalData"
            modal-class="modal-right"
            hide-footer
          >
            <h3 class="mb-4">
              <blockquote>{{ modalData.title }}</blockquote>
            </h3>
            <p class="font-weight-semibold text-one mb-1">Raised By</p>
            <p class="text-muted mb-3 text-small">{{ modalData.details }}</p>
            <p class="font-weight-semibold text-one mb-1">Day Raised</p>
            <p class="text-muted mb-3 text-small">{{ modalData.day_name }}</p>
            <p class="font-weight-semibold text-one mb-1">Round Raised</p>
            <p class="text-muted mb-3 text-small">{{ modalData.week_name }}</p>
            <p class="font-weight-semibold text-one mb-1">Status</p>
            <v-select
              :options="status.options"
              :value="modalData.status"
              label="label"
              :reduce="(status) => status.id"
              :dir="direction"
              @input="statusChanged($event)"
            />
          </b-modal>

          <b-modal
            id="modalVmb"
            ref="modalVmb"
            body-class="vmb-survey-modal-body"
            modal-class="modalVmb"
            size="md"
            hide-header
            hide-footer
            @hide="resetForm"
          >
            <div class="mx-auto my-auto lodgement-container">
              <div class="d-flex mb-4">
                <h2 class="align-self-center pb-0 mb-0" v-if="currentUser">
                  {{ currentUser.team }} {{ $t('pages.vmb.form.pre-huddle') }}
                </h2>
                <p class="align-self-center p-0 m-0 mx-2">
                  <b-badge
                    v-if="openTeamWeek"
                    variant="outline-info"
                    class="animate__animated animate__fadeInDown"
                  >
                    {{ openTeamWeek.open_week_name }}
                  </b-badge>
                </p>
                <p class="align-self-center p-0 m-0">
                  <b-badge
                    v-if="openTeamWeek"
                    variant="outline-new"
                    class="animate__animated animate__fadeInDown"
                  >
                    {{ openTeamWeek.open_day_name }}
                  </b-badge>
                </p>
              </div>
              <b-form @submit.prevent="formSubmit">
                <div v-show="step === 1" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>{{ $t('pages.vmb.form.what-went-well.header') }}</h3>
                      <label class="pl-0 mb-2 col-12">
                        {{ $t('pages.vmb.form.what-went-well.title') }}
                      </label>
                      <b-form-tags
                        input-id="tags-whatWentWell"
                        v-model="newDashboardItems.whatWentWell"
                        :placeholder="$t('pages.vmb.form.placeholder')"
                        tag-variant="info"
                      ></b-form-tags>
                    </b-input-group>
                    <div class="d-flex justify-content-center align-items-center step-btn ml-auto">
                      <b-button
                        variant="primary"
                        size="sm"
                        @click.prevent="next()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.next-button') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-show="step === 2" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>{{ $t('pages.vmb.form.communications.header') }}</h3>
                      <label class="pl-0 mb-2 col-12">
                        {{ $t('pages.vmb.form.communications.title') }}
                      </label>
                      <b-form-tags
                        input-id="tags-communication"
                        v-model="newDashboardItems.communication"
                        :placeholder="$t('pages.vmb.form.placeholder')"
                        tag-variant="info"
                      ></b-form-tags>
                    </b-input-group>
                    <div class="d-flex align-items-center step-btn ml-auto">
                      <b-button
                        variant="light"
                        size="sm"
                        @click.prevent="prev()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.prev-button') }}
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-2 btn-shadow"
                        @click.prevent="next()"
                      >
                        {{ $t('pages.vmb.form.next-button') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-show="step === 3" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>
                        {{ $t('pages.vmb.form.feedback.header') }}
                      </h3>
                      <label class="pl-0 mb-2 col-12">
                        {{ $t('pages.vmb.form.feedback.title') }}
                      </label>
                      <b-form-tags
                        input-id="tags-feedback"
                        v-model="newDashboardItems.feedback"
                        :placeholder="$t('pages.vmb.form.placeholder')"
                        tag-variant="info"
                      ></b-form-tags>
                    </b-input-group>
                    <div class="d-flex align-items-center step-btn ml-auto">
                      <b-button
                        variant="light"
                        size="sm"
                        @click.prevent="prev()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.prev-button') }}
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-2 btn-shadow"
                        @click.prevent="next()"
                      >
                        {{ $t('pages.vmb.form.next-button') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-show="step === 4" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>
                        {{ $t('pages.vmb.form.problems.header') }}
                      </h3>
                      <label class="pl-0 mb-2 col-12">
                        {{ $t('pages.vmb.form.problems.title') }}
                      </label>
                      <b-form-tags
                        input-id="tags-problems"
                        v-model="newDashboardItems.problems"
                        :placeholder="$t('pages.vmb.form.placeholder')"
                        tag-variant="info"
                      ></b-form-tags>
                    </b-input-group>
                    <div class="d-flex align-items-center step-btn ml-auto">
                      <b-button
                        variant="light"
                        size="sm"
                        @click.prevent="prev()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.prev-button') }}
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-2 btn-shadow"
                        @click.prevent="next()"
                      >
                        {{ $t('pages.vmb.form.next-button') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-show="step === 5" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>
                        {{ $t('pages.vmb.form.actions.header') }}
                      </h3>
                      <label class="pl-0 mb-2 col-12">
                        {{ $t('pages.vmb.form.actions.title') }}
                      </label>
                      <b-form-tags
                        input-id="tags-actions"
                        v-model="newDashboardItems.actions"
                        :placeholder="$t('pages.vmb.form.placeholder')"
                        tag-variant="info"
                      ></b-form-tags>
                    </b-input-group>
                    <div class="d-flex justify-content-end align-items-center step-btn ml-auto">
                      <b-button
                        variant="light"
                        size="sm"
                        @click.prevent="prev()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.prev-button') }}
                      </b-button>
                      <b-button
                        variant="primary"
                        size="sm"
                        class="ml-2 btn-shadow"
                        @click.prevent="next()"
                      >
                        {{ $t('pages.vmb.form.next-button') }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-show="step === 6" class="animate__animated animate__fadeIn">
                  <div class="d-flex flex-column">
                    <b-input-group class="mb-4">
                      <h3>
                        {{ $t('pages.vmb.form.people.header') }}
                      </h3>
                      <label class="pl-0 mb-4 col-12">
                        {{ $t('pages.vmb.form.people.title') }}
                      </label>
                      <div class="mt-4 mb-3 w-100">
                        <vue-slider
                          class="people-score-slider"
                          v-model="newDashboardItems.people"
                          :min="1"
                          :max="10"
                          :interval="1"
                          :tooltip="'always'"
                          :tooltip-placement="'top'"
                          :silent="true"
                        >
                          <template v-slot:tooltip="{ value, focus }">
                            <div
                              :class="[
                                'custom-tooltip',
                                `${setScoreVariant(value)}-tooltip`,
                                { focus }
                              ]"
                            >
                              <span>{{ value }}</span>
                            </div>
                          </template>
                        </vue-slider>
                      </div>
                      <div align-v="center" class="d-flex emo-group w-100">
                        <div
                          class="mb-2 pl-0 pr-0 w-20 text-center animate__animated"
                          :class="
                            setEmoticonEffect([1, 2], newDashboardItems.people)
                              ? 'opacity-100 animate__bounceIn'
                              : 'opacity-30'
                          "
                        >
                          <img src="/assets/img/svg/emo-worst.svg" alt class="mb-1" />
                          <p>{{ $t('pages.vmb.form.people.help') }}</p>
                        </div>
                        <div
                          class="mb-2 pl-0 pr-0 w-20 text-center animate__animated"
                          :class="
                            setEmoticonEffect([3, 4], newDashboardItems.people)
                              ? 'opacity-100 animate__bounceIn'
                              : 'opacity-30'
                          "
                        >
                          <img src="/assets/img/svg/emo-poor.svg" alt class="mb-1" />
                          <p>{{ $t('pages.vmb.form.people.not-good') }}</p>
                        </div>
                        <div
                          class="mb-2 pl-0 pr-0 w-20 text-center animate__animated"
                          :class="
                            setEmoticonEffect([5, 6], newDashboardItems.people)
                              ? 'opacity-100 animate__bounceIn'
                              : 'opacity-30'
                          "
                        >
                          <img src="/assets/img/svg/emo-average.svg" alt class="mb-1" />
                          <p>{{ $t('pages.vmb.form.people.average') }}</p>
                        </div>
                        <div
                          class="mb-2 pl-0 pr-0 w-20 text-center animate__animated"
                          :class="
                            setEmoticonEffect([7, 8], newDashboardItems.people)
                              ? 'opacity-100 animate__bounceIn'
                              : 'opacity-30'
                          "
                        >
                          <img src="/assets/img/svg/emo-good.svg" alt class="mb-1" />
                          <p>{{ $t('pages.vmb.form.people.good') }}</p>
                        </div>
                        <div
                          class="mb-2 pl-0 pr-0 w-20 text-center animate__animated"
                          :class="
                            setEmoticonEffect([9, 10], newDashboardItems.people)
                              ? 'opacity-100 animate__bounceIn'
                              : 'opacity-30'
                          "
                        >
                          <img src="/assets/img/svg/emo-excellent.svg" alt class="mb-1" />
                          <p>{{ $t('pages.vmb.form.people.fantastic') }}</p>
                        </div>
                      </div>
                    </b-input-group>
                    <div class="d-flex justify-content-end align-items-center step-btn mx-2">
                      <b-button
                        variant="light"
                        size="sm"
                        @click.prevent="prev()"
                        class="btn-shadow"
                      >
                        {{ $t('pages.vmb.form.prev-button') }}
                      </b-button>
                      <processing-button
                        variant="new"
                        size="sm"
                        :label="$t('pages.vmb.form.submit-button')"
                        class="ml-2 align-self-center btn-shadow"
                        :processing="processing"
                      ></processing-button>
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-modal>
        </b-row>
      </template>
    </dashboard-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { getDirection } from '@/utils'
import { initializeSurvey } from '@/utils/initialization'
import { initializeVmbModules } from '@/utils/initialization'
import {
  claimsProcessedChartOptions,
  accuracyChartOptions,
  profitabilityChartOptions
} from '@/components/Dashboard/config'
import BarChart from '@/components/Charts/Bar'
import CommentItems from '@/components/Dashboard/CommentItems'
import GradientWithRadialProgressCard from '@/components/Cards/GradientWithRadialProgressCard'
import InputTag from '@/components/Form/InputTag'
import DashboardLayout from '@/components/Layouts/DashboardLayout'
import ProcessingButton from '@/components/Common/ProcessingButton'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import RadialProgressBar from 'vue-radial-progress'
import RadialProgressCard from '@/components/Cards/RadialProgressCard'
import ScaleLoader from 'vue-spinner/src/ScaleLoader'
import TeamSelector from '@/components/Control/TeamSelector.vue'
import ToDoItems from '@/components/Dashboard/ToDoItems'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  components: {
    BarChart,
    CommentItems,
    DashboardLayout,
    GradientWithRadialProgressCard,
    InputTag,
    ProcessingButton,
    PulseLoader,
    RadialProgressBar,
    RadialProgressCard,
    ScaleLoader,
    TeamSelector,
    ToDoItems,
    vSelect,
    VueSlider
  },
  data() {
    return {
      accuracyChartOptions: accuracyChartOptions,
      activeTeam: null,
      activeTeamIndex: 0,
      chartColor: {
        positive: '#03BFAD',
        neutral: '#FFBA52',
        negative: '#F17363'
      },
      claimsProcessedChartOptions: claimsProcessedChartOptions,
      currentTeamWeek: null,
      direction: getDirection().direction,
      fill: variables.primaryColor,
      loader: {
        color: '#2d7281'
      },
      loadingAction: false,
      loadingDashboardData: false,
      loadingElement: 0,
      loadingProblem: false,
      modalData: null,
      newDashboardItems: initializeSurvey(),
      options: {
        height: 20,
        dotSize: 30,
        dotHeight: null,
        dotWidth: null
      },
      processing: false,
      profitabilityChartOptions: profitabilityChartOptions,
      showModal: false,
      step: 1,
      teams: [],
      vmbData: initializeVmbModules(),
      vmbRoute: ''
    }
  },
  mixins: [dataPreloadMixin],
  methods: {
    ...mapActions([
      'getStatus',
      'fetchVmbStatus',
      'fetchVmbAllData',
      'fetchVmbObservationData',
      'fetchVmbChartData',
      'submitVmbItems',
      'updateVmbObservationStatus'
    ]),
    ...mapMutations(['upsertDashboardItems']),
    onClickItem(value) {
      this.openModal(value)
    },
    hideModal(refname) {
      this.$refs[refname].hide()
    },
    openModal(data) {
      this.showModal = true
      this.modalData = data
    },
    async onChangeItem(item) {
      this.loadingElement = item.type
      await setTimeout(() => {
        this.updateVmbObservationStatus(item)
        this.loadingElement = 0
      }, 2000)
    },
    async statusChanged(value) {
      let md = this.modalData
      let obsUpdate = {
        id: md.id,
        type: md.type,
        status: value
      }
      await this.updateVmbObservationStatus(obsUpdate)
      this.hideModal('modalright')
    },
    prev() {
      this.step--
    },
    next() {
      this.step++
    },
    async getClaimsProcessedChart() {
      try {
        await this.$store.dispatch('getClaimsProcessedChart')
      } catch (e) {
        console.log(e.response)
      }
    },
    addProblem(event) {
      if (event.which === 13) {
        console.log(this.problems)
        this.problems.push({ value: '' })
      }
    },
    async formSubmit() {
      this.processing = true
      await this.submitVmbItems(this.newDashboardItems)
      this.processing = false
      await this.resetForm()
    },
    async resetForm() {
      this.newDashboardItems = await initializeSurvey()
      await this.setPeopleScore()
      this.processing = false
      this.step = 1
      this.hideModal('modalVmb')
    },
    async setCurrentTeamWeekDay() {
      this.newDashboardItems.userId = this.currentUser.id
      this.newDashboardItems.weekId = this.openTeamWeek ? this.openTeamWeek.open_week_id : null
      this.newDashboardItems.dayId = this.openTeamWeek ? this.openTeamWeek.open_day_id : null
    },
    async setPeopleScore() {
      const userIndex = this.vmbData.people.findIndex(
        (item) => item.user_id === this.currentUser.id
      )

      if (userIndex !== -1) {
        this.newDashboardItems.people = this.vmbData.people[userIndex].title
      }
    },
    async resetState() {
      this.$store.commit('resetState')
    },
    async getDashboardData() {
      await this.getStatus()
      this.loadingDashboardData = true
      await Promise.all([
        this.fetchVmbObservationData({
          simulationId: this.currentSimulation.id,
          teamId: this.currentSimulationTeam.id
        }),
        this.fetchVmbChartData({
          simulationId: this.currentSimulation.id,
          teamId: this.currentSimulationTeam.id
        })
      ])
      this.loadingDashboardData = false
    },
    async onVmbClick() {
      await this.setCurrentTeamWeekDay()
      await this.$refs['modalVmb'].show()
    },
    setEmoticonEffect(scores, score) {
      return scores.includes(score)
    },
    setScoreVariant(score) {
      let variant = ''
      switch (Number(score)) {
        case 0:
        case 1:
        case 2:
          variant = 'help'
          break
        case 3:
        case 4:
          variant = 'not-good'
          break
        case 5:
        case 6:
          variant = 'average'
          break
        case 7:
        case 8:
          variant = 'good'
          break
        default:
          variant = 'fantastic'
      }
      return variant
    }
  },
  computed: {
    ...mapGetters([
      'accuracyChart',
      'claimsProcessedChart',
      'currentSimulation',
      'currentSimulationTeam',
      'currentUser',
      'error',
      'getMenuType',
      'openTeamWeek',
      'profitabilityChart',
      'simulationTeams',
      'status',
      'teamWeeks',
      'vmbCharts',
      'vmbObservationData'
    ]),
    ...mapGetters('loading', [
      'loadingSimulationTeams',
      'loadingVmbData',
      'loadingVmbPerformanceCharts',
      'loadingVmbWeeklyData',
      'loadingVmbYesterdayData'
    ]),
    hasTeams() {
      return this.teams.length > 0
    },
    orderedItems: function () {
      return (data) => {
        let filteredData = _.filter(data, (item) => item.status === 1)
        return _.orderBy(filteredData, ['status', 'week_id', 'day_id'], ['asc', 'desc', 'desc'])
      }
    },
    setChartColor: function () {
      return (data) => {
        if (data < 6) {
          return this.chartColor.negative
        } else if (data >= 6 && data < 8) {
          return this.chartColor.neutral
        } else {
          return this.chartColor.positive
        }
      }
    }
  },
  watch: {
    currentSimulation: {
      async handler(newValue) {
        if (newValue) {
          this.vmbRoute = `/my-simulations/${newValue.code}/vmb`
        }
      },
      immediate: true
    },
    currentSimulationTeam: {
      async handler(newValue) {
        if (newValue) {
          this.getDashboardData()
        }
      },
      immediate: true
    },
    error(val) {
      if (val != null) {
        this.$notify('error', 'Dashboard Error', val, {
          duration: 3000,
          permanent: false
        })
      }
    },
    simulationTeams: {
      async handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.teams = await _.cloneDeep(newValue)
          this.activeTeam = this.teams[0]
        }
      },
      immediate: true
    },
    vmbObservationData: {
      async handler(newValue) {
        if (newValue) {
          this.vmbData = await _.cloneDeep(newValue)

          await this.setPeopleScore()
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
